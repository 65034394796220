import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {
  TransitionWrapper,
  LandingWrapper,
  LandingBackground
} from '../../utils/style';
import HeaderTitle from '../HeaderTitle';

/**
 * A 404 routing component.
 */
const NoMatch = (props) => (
  <React.Fragment>
    <Helmet>
      <title>Page Not Found | Pixel Sketch</title>
      <meta name="robots" content="noindex,follow" />
    </Helmet>
    <TransitionWrapper>
      <LandingWrapper>
        <LandingBackground height={props.height} />
        <HeaderTitle
          text='That URL does not exist.'
          maxWidth={['100%', '100%']}
        />
      </LandingWrapper>
    </TransitionWrapper>
  </React.Fragment>
);

NoMatch.propTypes = {
  height: PropTypes.number.isRequired
}

export default NoMatch;
