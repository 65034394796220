import React from 'react';
import PropTypes from 'prop-types';
import {css} from 'styled-components';

import {Widget} from './style';
import {routes} from '../../utils/routes';
import ScrollNavigate from '../ScrollNavigate';

// Scroll Navigate Styling.
const buttonStyling = css`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: ${props => props.theme.fonts.size.paragraph};
  position: absolute;
  text-decoration: underline;
`;

const previousButton = css`
  ${buttonStyling}
  left: 0;
`;

const nextButton = css`
  ${buttonStyling}
  right: 0;
`;

/**
 * A paginator widget for cycling through an array of content.
 */
class PaginatorWidget extends React.Component {
  /**
   * Creates a PaginatorWidget component.
   */
  constructor(props) {
    super(props);
    this.state = {
      previousProject: {},
      nextProject: {}
    }
  }

  /**
   * Invoked immediately before the component is mounted.
   */
  componentDidMount() {
    const {projects, currentProject} = this.props;
    let index;
    // Get the current project's index location.
    if (currentProject) {
      index = projects.findIndex((proj) => proj.name === currentProject.name);
    }

    // Calculate the previous and next project in the list.
    if (index !== -1) {
      let prevProj;
      if (index !== 0) {
        prevProj = projects[index - 1];
      }
      else {
        prevProj = projects[projects.length - 1];
      }

      let nextProj;
      if (index !== projects.length - 1) {
        nextProj = projects[index + 1];
      }
      else {
        nextProj = projects[0];
      }

      this.setState({previousProject: prevProj, nextProject: nextProj});
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const {previousProject, nextProject} = this.state;
    return (
      <Widget>
        <ScrollNavigate
          newPageURL={`${routes.projects}/${previousProject.slug}`}
          css={previousButton}>
          Previous
        </ScrollNavigate>
        <ScrollNavigate
          newPageURL={`${routes.projects}/${nextProject.slug}`}
          css={nextButton}>
          Next
        </ScrollNavigate>
      </Widget>
    );
  }
}

PaginatorWidget.propTypes = {
  projects: PropTypes.array.isRequired,
  currentProject: PropTypes.object.isRequired
}

export default PaginatorWidget;
