import styled from 'styled-components';

export const HeaderSection = styled.section`
  position: relative;
`;

export const HeaderBackground = styled.div`
  background: linear-gradient(to right, ${props => props.theme.colors.gradient});
  height: 20.8125rem;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    height: 28.125rem;
  }
`;
