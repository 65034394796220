import styled, {css} from 'styled-components';

const defaultCSS = css`
  background: #FAFAFA;
  border: 2px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  font-family: sans-serif;
  font-size: 1rem;
  height: 10rem;
  padding: 1rem 1.25rem;
  resize: vertical;
  width: 100%;
`;

export const TextAreaField = styled.textarea`
  ${props => props.css};
`;

TextAreaField.defaultProps = {
  css: defaultCSS
}
