import React from 'react';
import ReactGA from 'react-ga';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Reset} from 'styled-reset';
import {GlobalStyle} from './style';
import {ThemeProvider} from 'styled-components';
import {theme} from '../../utils/themes';
import {routes} from '../../utils/routes';
import {transitionLength} from '../../utils/animations';
import {getAPIData} from '../../utils/api';
import smoothscroll from 'smoothscroll-polyfill';
import 'whatwg-fetch';

import GAListener from '../GAListener';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {PageWrapper} from '../../utils/style';
import NavigationBar from '../NavigationBar';
import IndexPage from '../IndexPage';
import ProjectsPage from '../ProjectsPage';
import ProjectPage from '../ProjectPage';
import ContactPage from '../ContactPage';
import PrivacyPage from '../PrivacyPage';
import NoMatch from '../NoMatch';

// Initialize Google Analytics.
ReactGA.initialize('UA-152342169-1');

/**
 * The entry-point class for the application.
 */
class App extends React.Component {
  /**
   * Create an App component.
   * @constructor
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      isTransitioning: false,
      overlayActive: false,
      pageLoad: true,
      refresh: true,
      APIData: {},
      viewportHeight: 0
    }
    this.toggleOverlayActive = this.toggleOverlayActive.bind(this);
    this.setRefresh = this.setRefresh.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  /**
   * Invoked immediately after the component is loaded.
   */
  async componentDidMount() {
    // Kick off the smooth scroll polyfill.
    smoothscroll.polyfill();

    // Get the viewport height.
    this.handleResize();

    // Fetches the API data.
    const APIData = await getAPIData();
    this.setState({APIData: APIData});
  }

  /**
   * Activates / deactivates navigation overlay and removes page load
   * restriction so animations can be played both ways.
   */
  toggleOverlayActive() {
    if (this.state.pageLoad) {
      this.setState((prevState) => ({
        overlayActive: !prevState.overlayActive,
        pageLoad: false
      }));
    }
    else {
      this.setState((prevState) => ({overlayActive: !prevState.overlayActive}));
    }
  }

  /**
   * Used to set the state that idicates whether the page has just been
   * refreshed or router navigation has taken place.
   */
  setRefresh(hasRefreshed) {
    this.setState({refresh: hasRefreshed});
  }

  /**
   * Updates the browser viewport height state.
   */
  handleResize() {
    const height = window.innerHeight;
    this.setState({viewportHeight: height});
  }

  /**
   * Renders the component.
   */
  render() {
    const {
      overlayActive,
      pageLoad,
      refresh,
      APIData,
      viewportHeight
    } = this.state;
    return (
      <React.Fragment>
        <Reset />
        <GlobalStyle overlayActive={overlayActive} />
        <ThemeProvider theme={theme}>
          <Router>
            <GAListener>
              <Route render={({location}) => (
                <PageWrapper style={{position: 'relative', height: '100%'}}>
                  <NavigationBar
                    overlayActive={overlayActive}
                    pageLoad={pageLoad}
                    toggleOverlayFunc={this.toggleOverlayActive}
                  />
                  <TransitionGroup component={null}>
                    <CSSTransition
                      timeout={transitionLength * 1000}
                      classNames='page'
                      key={location.key}>
                      <Switch location={location}>
                        <Route
                          exact
                          path={routes.profile}
                          render={() =>
                            <IndexPage
                              APIData={APIData}
                              isPageRefreshed={refresh}
                              pageRefreshFunc={this.setRefresh}
                              viewportHeight={viewportHeight}
                            />}
                        />
                        <Route
                          exact
                          path={routes.projects}
                          render={() =>
                            <ProjectsPage
                              APIData={APIData}
                              isPageRefreshed={refresh}
                              pageRefreshFunc={this.setRefresh}
                            />}
                        />
                        <Route
                          exact
                          path={`${routes.projects}/:project`}
                          render={({match}) =>
                            <ProjectPage
                              projects={APIData.projects}
                              isPageRefreshed={refresh}
                              pageRefreshFunc={this.setRefresh}
                              viewportHeight={viewportHeight}
                              match={match}
                            />}
                        />
                        <Route
                          exact
                          path={routes.contact}
                          render={() =>
                            <ContactPage
                              isPageRefreshed={refresh}
                              pageRefreshFunc={this.setRefresh}
                            />}
                        />
                        <Route
                          exact
                          path={routes.privacy}
                          render={() =>
                            <PrivacyPage
                              isPageRefreshed={refresh}
                              pageRefreshFunc={this.setRefresh}
                            />}
                        />
                        <Route render={() =>
                          <NoMatch height={viewportHeight} />}
                        />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </PageWrapper>
              )} />
            </GAListener>
          </Router>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

export default App;
