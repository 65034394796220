import React from 'react';
import PropTypes from 'prop-types';
import {css} from 'styled-components';
import {routes} from '../../utils/routes';
import {Helmet} from 'react-helmet';

import {
  TransitionWrapper,
  ContentWrapper,
  PageWrapperFlex,
} from '../../utils/style';
import {
  FlexWrapper,
  FormWrapper,
  PhoneWrapper,
  Title,
  Paragraph,
  GradientText
} from './style';
import Header from '../Header';
import ContactForm from '../ContactForm';
import LoadingWidget from '../LoadingWidget';
import Footer from '../Footer';

const checkboxInfo = {
  company: `Pixel Sketch's`,
  terms: routes.terms,
  privacy: routes.privacy
}

// Contact Form Styling.
const fieldWrapperCSS = css`
  margin: 1.875rem 0;
  position: relative;
`;

const inputCSS = css`
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#FAFAFA, #FAFAFA),
                    linear-gradient(to right, ${props => props.theme.colors.gradient});
  background-origin: border-box;
  background-size: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  max-width: 40rem;
  padding: 1rem 1.25rem;
  width: 100%;
`;

const textAreaCSS = css`
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#FAFAFA, #FAFAFA),
                    linear-gradient(to right, ${props => props.theme.colors.gradient});
  background-origin: border-box;
  background-size: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  display: block;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  max-width: 40rem;
  padding: 1rem 1.25rem;
  resize: vertical;
  width: 100%;
`;

const checkboxWrapperCSS = css`
  margin-bottom: 1.875rem;
  margin-top: 2.0625rem;
  position: relative;

  & input:checked + label {
    color: #000;

    & a {
      color: #000;
    }
  }

  & input:checked + label:after {
    opacity: 1;
  }
`;

const checkboxLabelCSS = css`
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 2.5rem;
  position: relative;
  vertical-align: top;

  & span {
    color: #000;
    cursor: pointer;
    text-decoration: underline;
  }

  & div {
    display: inline;
    text-decoration: underline;
  }


  ${props => props.error && css`
    color: red;

    & span {
      color: red;
    }
  `};

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    background-clip: padding-box, border-box;
    background-image: linear-gradient(#FFF, #FFF),
                      linear-gradient(
                        to right, ${props => props.theme.colors.gradient}
                      );
    background-origin: border-box;
    border: 2px solid transparent;
    border-radius: 4px;

    display: inline-block;
    height: 16px;
    left: 0;
    top: -3px;
    width: 16px;
  }

  &:after {
    border-image: linear-gradient(
      to right bottom, ${props => props.theme.colors.gradient}
    ) 1;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    display: inline-block;
    height: 4px;
    left: 5px;
    opacity: 0;
    top: 3px;
    transform: rotate(-55deg);
    transition: opacity 0.2s ease-out;
    width: 8px;
  }
`;

const checkboxCSS = {
  wrapper: checkboxWrapperCSS,
  label: checkboxLabelCSS
}

const buttonCSS = css`
  background: linear-gradient(to right, ${props => props.theme.colors.gradient});
  border: none;
  color: #fff;
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1rem;
  font-weight: bold;
  margin-top: 2.5rem;
  max-width: 9.375rem;
  padding: 1rem 2.8125rem;
  width: 100%;
`;

const googleBrandingCSS = css`
  color: #C5C5C5;
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem;
  max-width: 19.6875rem;

  & a {
    color: #C5C5C5;
  }
`;

const errorMessageCSS = css`
  color: red;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 0.75rem;
  position: absolute;
  margin-top: 0.3125rem;
`;

const sentWrapperCSS = css`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  max-width: 40rem;
  position: relative;
  white-space: pre-line;
`;

const formCSS = {
  field: fieldWrapperCSS,
  input: inputCSS,
  textArea: textAreaCSS,
  checkbox: checkboxCSS,
  button: buttonCSS,
  google: googleBrandingCSS,
  error: errorMessageCSS,
  sent: sentWrapperCSS
}

/**
 * The contact page component.
 */
class ContactPage extends React.Component {
  /**
   * Create a ContactPage component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
      textHidden: false
    }
    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
    this.hideText = this.hideText.bind(this);
  }

  /**
   * Invoked immediately after the component is loaded.
   */
  componentDidMount() {
    if (this.props.isPageRefreshed) {
      this.props.pageRefreshFunc(false);
    }
  }

  /**
   * Updates the state representing the height of the page's header element.
   * @param {number} height - The height of the header in pixels.
   */
  updateHeaderHeight(height) {
    this.setState({headerHeight: height});
  }

  /**
   * Displays / hides the contact form text.
   * @param {string} isHidden - The text to be shown / hidden.
   */
  hideText(isHidden) {
    this.setState({textHidden: isHidden});
  }

  /**
   * Renders the component.
   */
  render() {
    // The height for the flex wrapper to enable a sticky footer.
    const height = 'calc(100vh - ' + this.state.headerHeight + 'px)';
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact | Pixel Sketch</title>
          <meta
            name="description"
            content="Contact us for Bespoke Web Design and Development
                     services."
          />
          <link rel="canonical" href="https://pixelsketch.co.uk/contact" />
        </Helmet>
        <TransitionWrapper>
          <Header
            text="Let's Talk."
            updateHeightFunc={this.updateHeaderHeight}
          />
          <PageWrapperFlex style={{height: height}}>
            <ContentWrapper>
              <FlexWrapper>
                <FormWrapper>
                  <Title>Get in Touch</Title>
                  {!this.state.textHidden
                    ? <Paragraph>
                        Please fill out the quick form and we will be in touch with
                        lightning speed.
                      </Paragraph>
                    : null}
                  <ContactForm
                    siteKey='6Leyi7IUAAAAAChD13AwDPObsgyWFCxkErxer-G1'
                    checkboxInfo={checkboxInfo}
                    hideTextFunc={this.hideText}
                    css={formCSS}>
                    <LoadingWidget />
                  </ContactForm>
                </FormWrapper>
                <PhoneWrapper>
                  <Title>Call Us</Title>
                  <Paragraph>
                    Old-fashioned phone calls work too:
                  </Paragraph>
                  <GradientText>+44 (0)7719351666</GradientText>
                </PhoneWrapper>
              </FlexWrapper>
            </ContentWrapper>
            <Footer />
          </PageWrapperFlex>
        </TransitionWrapper>
      </React.Fragment>
    );
  }
}

ContactPage.propTypes = {
  isPageRefreshed: PropTypes.bool.isRequired,
  pageRefreshFunc: PropTypes.func.isRequired
}

export default ContactPage;
