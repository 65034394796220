import styled, {css} from 'styled-components';

const defaultCSS = css`
  background: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  svg {
    display: block;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const Button = styled.button`
  border: 0;
  padding: 0;
  ${props => props.css}
`;

Button.defaultProps = {
  css: defaultCSS
}
