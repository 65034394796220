import styled, {css} from 'styled-components';

export const Heading = styled.h2`
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;
  margin-bottom: 1.75rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.75rem;
    margin-bottom: 1.25rem;
  }
`;

export const Paragraph = styled.p`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 3.125rem;
  white-space: pre-wrap;

  & a {
    color: #000;
  }

  ${props => props.middle && css`
    margin-bottom: 1.75rem;

    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      margin-bottom: 1.5625rem;
    }
  `};
`;

export const List = styled.ul`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  list-style: disc;
  margin-bottom: 1.75rem;
  margin-left: 2rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin-bottom: 1.5625rem;
    margin-left: 3rem;
  }
`;

export const ListItem = styled.li`
  margin-bottom: 1rem
  padding-left: 1rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin-bottom: 0.5rem;
  }
`;

export const Table = styled.div`
  display: none;
  flex-wrap: wrap;

  & div:nth-last-of-type(-n+2) {
    border: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: flex;
    flex-direction: row;
  }

  ${props => props.mobile && css`
    display: flex;

    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      display: none;
    }
  `};
`;

export const HeaderCell = styled.div`
  align-items: center;
  background: #000;
  border-bottom: 1px solid #5a5a5a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    align-items: flex-start;
    border: 0;
    padding: 1.5rem 0;
  }

  ${props => props.provider && css`
    width: 40%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 24%;
    }
  `};

  ${props => props.cookie && css`
    width: 40%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 20%;
    }
  `};

  ${props => props.purpose && css`
    width: 40%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 56%;
    }
  `};
`;

export const ContentCell = styled.div`
  align-items: center;
  background: #f8f8f8;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    align-items: flex-start;
    border: 0;
    padding: 1.5rem 0;
  }

  ${props => props.provider && css`
    width: 60%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 24%;
    }
  `};

  ${props => props.cookie && css`
    width: 60%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 20%;
    }
  `};

  ${props => props.purpose && css`
    width: 60%;
    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      width: 56%;
    }
  `};
`;

export const TableTitle = styled.h3`
  color: #fff;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;
  line-height: 2rem;
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.5rem;
    padding: 0 1.875rem;
  }
`;

export const TableContent = styled.p`
  font-family: ${props => props.theme.fonts.family.paragraph};
  line-height: 1.625rem;
  padding: 0 1rem;
  white-space: pre-wrap;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    padding: 0 1.875rem;
  }
`;
