import styled from 'styled-components';

const OverflowSection = styled.section`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Profile = styled(OverflowSection)``;

export const Capabilities = styled(OverflowSection)`
  background: #000;
`;

export const Projects = styled(OverflowSection)``;

export const CapFlexWrapper = styled.div`
  display: block;

  @media (min-width: ${props => props.theme.breakpoints.capabilitiesContent}) {
    display: flex;
  }
`;

export const PlainTitle = styled.h2`
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: ${props => props.theme.fonts.size.heading[0]};
  margin-bottom: 4.375rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: ${props => props.theme.fonts.size.heading[1]};
    margin-bottom: 6.25rem;
    width: 40%;
  }

  @media (min-width: ${props => props.theme.breakpoints.capabilitiesContent}) {
    margin-bottom: 0;
  }
`;

export const CapTitle = styled.h3`
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;
  width: 50%;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.75rem;
  }
`;

export const CapText = styled.p`
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  margin-bottom: 3.125rem;
  width: 50%;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1rem;
    line-height: 1.625rem;
  }
`;

export const Skills = styled.div`
  display: flex;
  flex-flow: row wrap;

  & > ${CapText}:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.capabilitiesContent}) {
    width: 60%;
    margin-top: 0.6rem;
  }
`;

export const CapDivider = styled.hr`
  background: ${props => props.theme.colors.heading};
  border: none;
  height: 1px;
  margin: 3.125rem 0;
  opacity: 0.5;
`;

export const Services = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 2.5rem;

  @media (min-width: ${props => props.theme.breakpoints.capabilitiesContent}) {
    margin-top: 0;
    width: 64%;
  }
`;

export const ServiceTitle = styled(CapTitle)`
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    width: 36%;
  }
`;

export const ServiceText = styled.p`
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 0.75rem;
  line-height: 1.6rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1rem;
    line-height: 2.25rem;
  }
`;

export const TabFlexWrapper = styled.div`
  & > div:first-of-type {
    margin-top: 0;
  }

  & > div:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: flex;
    flex-flow: row wrap;

    & > div {
      margin-top: 1.875rem;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        margin-top: 0;
      }

      &:nth-of-type(odd) {
        margin-right: 0.9375rem;
      }

      &:nth-of-type(even) {
        margin-left: 0.9375rem;
      }
    }
  }
`;
