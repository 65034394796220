import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {
  TransitionWrapper,
  ContentWrapper,
  PageWrapperFlex,
  LoadingWidgetWrapper,
  Link
} from '../../utils/style';
import {
  TextWrapper,
  EnterpriseWrapper,
  Enterprise,
  Description,
  DisplayImageHeader,
  ImageHeaderTitle,
  DisplayImage
} from './style';
import Header from '../Header';
import Footer from  '../Footer';
import NoMatch from '../NoMatch';
import LoadingWidget from '../LoadingWidget';
import PaginatorWidget from '../PaginatorWidget';

/**
 * A project page component.
 */
 class ProjectPage extends React.Component {
   /**
    * Creates a ProjectPage component.
    * @param {object} props - The component props.
    */
   constructor(props) {
     super(props);
     this.state = {
       headerHeight: 0
     }
     this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
   }

   /**
    * Invoked immediately after the comonent is loaded.
    */
   componentDidMount() {
     if (this.props.isPageRefreshed) {
       this.props.pageRefreshFunc(false);
     }
   }

   /**
    * Updates the state representing the height of the page's header element.
    * @param {number} height - The height of the header in pixels.
    */
   updateHeaderHeight(height) {
     this.setState({headerHeight: height});
   }

   /**
    * Renders the component.
    */
   render() {
     const {projects, match} = this.props;
     let project;
     if (projects) {
       project = projects.find(
         (project) => project.slug === match.params.project
       );
     }
     // The height for the flex wrapper to enable a sticky footer.
     const height = 'calc(100vh - ' + this.state.headerHeight + 'px)';
     return (
       projects && projects.length > 0
        ? project
            ? <React.Fragment>
                <Helmet>
                  <title>{project.name} | Pixel Sketch</title>
                  <meta
                    name="description"
                    content={project.description.substring(0, 160)}
                  />
                  <link
                    rel="canonical"
                    href={`https://pixelsketch.co.uk/projects/${project.slug}`}
                  />
                </Helmet>
                <TransitionWrapper>
                  <Header
                    text={project.name}
                    updateHeightFunc={this.updateHeaderHeight}
                  />
                  <PageWrapperFlex style = {{height: height}}>
                    <ContentWrapper>
                      <TextWrapper>
                        <EnterpriseWrapper>
                          <Enterprise>{project.enterprise}</Enterprise>
                          {project.url
                            ? <Link
                                href={project.url}
                                target='_blank'
                                hiddenSmall>
                                Website
                              </Link>
                            : null}
                        </EnterpriseWrapper>
                        <Description>{project.description}</Description>
                        {project.url
                          ? <Link
                              href={project.url}
                              target='_blank' 
                              hiddenLarge>
                              Website
                            </Link>
                          : null}
                      </TextWrapper>
                      <DisplayImageHeader>
                        <ImageHeaderTitle>{project.name}</ImageHeaderTitle>
                      </DisplayImageHeader>
                      {project.displayImageSmall && project.displayImageLarge
                        ? <DisplayImage
                            src={project.displayImageSmall}
                            srcSet={`${project.displayImageSmall} 1240w,
                                     ${project.displayImageLarge} 2480w`}
                            sizes='calc(100vw - 12.5rem)'
                            alt={`${project.name} Website Images`}
                          />
                        : null}
                      <PaginatorWidget
                        projects={projects}
                        currentProject={project}
                      />
                    </ContentWrapper>
                    <Footer />
                  </PageWrapperFlex>
                </TransitionWrapper>
              </React.Fragment>
            : <NoMatch height={this.props.viewportHeight} />
        : <TransitionWrapper>
            <Header
              text='Fetching Project...'
              updateHeightFunc={this.updateHeaderHeight}
            />
            <PageWrapperFlex style = {{height: height}}>
              <ContentWrapper>
                <LoadingWidgetWrapper>
                  <LoadingWidget />
                </LoadingWidgetWrapper>
              </ContentWrapper>
              <Footer />
            </PageWrapperFlex>
          </TransitionWrapper>
     );
   }
 }

ProjectPage.propTypes = {
  projects: PropTypes.array,
  isPageRefreshed: PropTypes.bool.isRequired,
  pageRefreshFunc: PropTypes.func.isRequired,
  viewportHeight: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired
}

export default ProjectPage;
