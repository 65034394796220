import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    height: 100%;
  }

  #root {
    height: 100%;
    overflow-y: ${props => props.overlayActive ? 'hidden' : 'visible'};
  }

  .grecaptcha-badge { visibility: hidden; }
`;
