import React from 'react';
import PropTypes from 'prop-types';

import {TypedText} from './style';
import {timings, transitionLength} from '../../utils/animations';

/**
 * A typewriter component.
 * Cycles through a text pool typing and erasing each line.
 */
class Typewriter extends React.Component {
  /**
   * Creates a Typewriter component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      displayedText: '',
      textPoolIndex: 0,
      isErasing: false,
      timeoutID: ''
    }
    this.tick = this.tick.bind(this);
  }

  /**
   * Invoked immediately after the component is mounted.
   */
  componentDidMount() {
    let timeoutID;
    if (!this.props.isPageRefreshed) {
      // The delay to allow for transition animations.
      const delay = transitionLength - timings.translateUp.duration;
      timeoutID = setTimeout(() => this.tick(), delay * 1000);
    }
    else {
      timeoutID = setTimeout(() => this.tick(), 250);
    }
    this.setState({timeoutID: timeoutID});
  }

  /**
   * Invoked immediately before the component is unmounted.
   */
  componentWillUnmount() {
    clearTimeout(this.state.timeoutID);
  }

  /**
   * One step of the typewriter.
   */
  tick() {
    const {textPool, periodDelay, tickDelayBounds} = this.props;
    const {displayedText, textPoolIndex, isErasing} = this.state;

    const index = textPoolIndex % textPool.length;
    let text = textPool[index];

    // Adjust the text length for writing or erasing.
    if (isErasing) { text = text.substring(0, displayedText.length - 1); }
    else { text = text.substring(0, displayedText.length + 1); }

    // Update the displayed text.
    this.setState({displayedText: text});

    // How long to pause between writing or erasing - simulates typing variance.
    let tickDelay = (tickDelayBounds.upper - Math.random()
                     * tickDelayBounds.lower);
    // Erasing is twice as fast as writing.
    if (isErasing) { tickDelay *= 0.5; }

    // Calculate the period delay (time before writing or erasing begins).
    if (!isErasing && displayedText === text) {
      tickDelay = periodDelay;
      this.setState({isErasing: true});
    }
    else if (isErasing && displayedText === '') {
      tickDelay = periodDelay * 0.25;
      this.setState((prevState) => ({
        isErasing: false,
        textPoolIndex: prevState.textPoolIndex + 1
      }));
    }

    const timeoutID = setTimeout(() => this.tick(), tickDelay);
    this.setState({timeoutID: timeoutID});
  }

  /**
   * Renders the component.
   */
  render() {
    return (
      <TypedText>{this.state.displayedText}</TypedText>
    );
  }
}

Typewriter.propTypes = {
  textPool: PropTypes.array,
  periodDelay: PropTypes.number,
  tickDelayBounds: PropTypes.object,
  isPageRefreshed: PropTypes.bool
}

Typewriter.defaultProps = {
  textPool: ['First Text.', 'Second Text.', 'Third Text.'],
  periodDelay: 1000,
  tickDelayBounds: {lower: 100, upper: 200},
  isPageRefreshed: false
}

export default Typewriter;
