import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from 'react-router-dom';

import {ScrollNavWrapper} from './style';

/**
 * A scroll navigate component.
 * Scrolls to the top of the page before transitioning to another page via
 * react router.
 */
class ScrollNavigate extends React.Component {
  /**
   * Creates a ScrollNavigate component.
   */
  constructor(props) {
    super(props);
    this.state = {
      toNewPage: false
    }
    this.handleClick = this.handleClick.bind(this);
    this.navigatePage = this.navigatePage.bind(this);
  }

  /**
   * Scrolls the page to the top before navigating.
   */
  handleClick(e) {
    // Stops accepting the contact form checkbox for terms and privacy policy
    // for contact form - bit hacky...
    e.stopPropagation();
    e.preventDefault();

    // Navigate if page is already at the top.
    if (window.scrollY === 0 || window.pageYOffset === 0) {
      this.navigatePage();
      return;
    }

    window.addEventListener('scroll', this.navigatePage);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Once the page is scrolled to the top, navigates to the new page by setting
   * state for a Redirect to render.
   */
  navigatePage() {
    if (window.scrollY === 0 || window.pageYOffset === 0) {
      this.setState({toNewPage: true});
      window.removeEventListener('scroll', this.navigatePage);
    }
  }

  /**
   * Renders the component.
   */
  render() {
    if (this.state.toNewPage) {
      return <Redirect to={this.props.newPageURL} push />
    }

    return (
      <ScrollNavWrapper onClick={this.handleClick} css={this.props.css}>
        {this.props.children}
      </ScrollNavWrapper>
    );
  }
}

ScrollNavigate.propTypes = {
  newPageURL: PropTypes.string.isRequired,
  css: PropTypes.array
}

export default ScrollNavigate;
