import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {routes} from '../../utils/routes';
import {css} from 'styled-components';
import {hamburgerDuration, timings} from '../../utils/animations';

import Hamburger from '../Hamburger';
import Menu from '../Menu';
import NavigationOverlay from '../NavigationOverlay';

import {
  Nav,
  Logo,
  CustomerBurgerWrapper,
  CustomBurger
} from './style';

const menuItems = [
  {name: 'Profile', route: routes.profile},
  {name: 'Projects', route: routes.projects},
  {name: 'Contact', route: routes.contact}
];

// Navigation Button Styling.
const buttonCSS = css`
  background: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

// Overlay Styling.
const navOverlayCSS = css`
  background: rgba(0, 0, 0, 1);
  opacity: ${props => props.active ? '1' : '0'};
  transition: opacity ${hamburgerDuration}s ease-out,
              visibility ${hamburgerDuration}s ease-out;
  visibility: ${props => props.active ? 'visible' : 'hidden'};
`;

const linkWrapperCSS = css`
  bottom: 0;
  padding: 0 1.875rem;
  padding-bottom: 3.125rem;
  opacity: ${props => props.active ? '1' : '0'};
  position: absolute;
  transform: ${props => props.active
              ? 'translateY(0)'
              : 'translateY(-20px)'};
  transition: transform ${hamburgerDuration}s ease-out
              ${timings.overlayFade.delay}s,
              opacity ${timings.overlayFade.duration}s ease-out
              ${timings.overlayFade.delay}s;
`;

const overlayLinkCSS = css`
  color: #FFF;
  display: block;
  font-family: sans-serif;
  font-size: 2.5rem;
  margin: 1.875rem 0;
  text-decoration: none;
  text-transform: uppercase;
`;

const overlayCSS = {
  overlay: navOverlayCSS,
  wrapper: linkWrapperCSS,
  link: overlayLinkCSS
}

/**
 * A navigation bar component.
 */
 const NavigationBar = (props) => (
   <Nav>
     <Link
       to={routes.profile}>
       <Logo />
     </Link>
     <Hamburger
       handleClickFunc={props.toggleOverlayFunc}
       css={buttonCSS}>
       <CustomerBurgerWrapper>
         {props.overlayActive
           ? <CustomBurger active />
           : <CustomBurger pageLoad={props.pageLoad} />}
       </CustomerBurgerWrapper>
     </Hamburger>
     <Menu items={menuItems} />
     <NavigationOverlay
       links={menuItems}
       active={props.overlayActive}
       toggleOverlayFunc={props.toggleOverlayFunc}
       css={overlayCSS}
     />
   </Nav>
 );

NavigationBar.propTypes = {
  overlayActive: PropTypes.bool.isRequired,
  pageLoad: PropTypes.bool.isRequired,
  toggleOverlayFunc: PropTypes.func.isRequired
}

export default withRouter(NavigationBar);
