import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {
  hamburgerTopCollapse,
  hamburgerTopReform,
  hamburgerBottomCollapse,
  hamburgerBottomReform,
  fadeIn,
  fadeOut,
  timings
} from '../../utils/animations';
import {ReactComponent as LogoIcon} from '../../assets/images/logo.svg';

export const Nav = styled.nav`
  margin: 0 ${props => props.theme.margins.runner.small};
  padding: ${props => props.theme.margins.runner.small} 0;
  position: relative;
  z-index: 1;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin: 0 ${props => props.theme.margins.runner.medium};
    padding: 3.125rem 0;
  }
`;

export const Logo = styled(LogoIcon)`
  height: auto;
  width: 9.625rem;
`;

export const CustomerBurgerWrapper = styled.div`
  height: 0.9375rem;
  position: relative;
  width: 1.5625rem;
`;

export const CustomBurger = styled.div`
  background: ${props => props.theme.colors.heading};
  height: 0.0625rem;
  position: absolute;
  width: 1.5625rem;

  &:before,
  &:after {
    background: ${props => props.theme.colors.heading};
    content: "";
    display: block;
    height: 0.0625rem;
    position: absolute;
    width: 1.5625rem;
  }

  &:before {
    top: 0.4375rem;
  }

  &:after {
    top: 0.875rem;
  }

  ${'' /* Prevents the animation playing on page load. */}
  ${props => !props.pageLoad && css`
    animation: ${hamburgerTopReform} ${timings.hamburgerTransition.duration}s ease forwards;

    &:before {
      animation: ${fadeIn} ${timings.hamburgerFade.duration}s ease ${timings.hamburgerFade.delay}s forwards;
      opacity: 0;
    }

    &:after {
      animation: ${hamburgerBottomReform} ${timings.hamburgerTransition.duration}s ease forwards;
    }
  `};

  ${props => props.active && css`
    animation: ${hamburgerTopCollapse} ${timings.hamburgerTransition.duration}s ease ${timings.hamburgerFade.delay}s forwards;

    &:before {
      animation: ${fadeOut} ${timings.hamburgerFade.duration}s ease forwards;
    }

    &:after {
      animation: ${hamburgerBottomCollapse} ${timings.hamburgerTransition.duration}s ease ${timings.hamburgerFade.delay}s forwards;
    }
  `};
`;

CustomBurger.propTypes = {
  pageLoad: PropTypes.bool
}
