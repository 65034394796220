import React from 'react';
import PropTypes from 'prop-types';

import {FormButton} from './style';

/**
 * A button component.
 * @param {object} props - The component props.
 */
const Button = (props) => (
  <FormButton css={props.css}>{props.text}</FormButton>
);

Button.propTypes = {
  text: PropTypes.string,
  css: PropTypes.array
}

Button.defaultProps = {
  text: 'SUBMIT'
}

export default Button;
