import React from 'react';
import PropTypes from 'prop-types';

import {TextAreaField} from './style';

/**
 * A text area field component.
 * @param {object} props - The component props.
 */
const TextArea = (props) => (
  <TextAreaField
    value={props.value}
    name={props.name}
    rows={props.rows}
    columns={props.columns}
    placeholder={props.placeholder}
    onChange={props.handleChange}
    css={props.css}
  />
);

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rows: PropTypes.number,
  columns: PropTypes.number,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  css: PropTypes.array
}

TextArea.defaultProps = {
  rows: 5,
  columns: 33,
  placeholder: 'Placeholder Text'
}

export default TextArea;
