import React from 'react';
import PropTypes from 'prop-types';

import {Title} from './style';

/**
 * The header title component.
 * @param {object} props - The component props.
 */
const HeaderTitle = (props) => (
  <Title maxWidth={props.maxWidth}>
    {props.text}
  </Title>
);

HeaderTitle.propTypes = {
  text: PropTypes.string,
  maxWidth: PropTypes.array
}

HeaderTitle.defaultProps = {
  text: 'Template header text.',
  maxWidth: ['14.3rem', '23.75rem']
}

export default HeaderTitle;
