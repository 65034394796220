import React from 'react';
import PropTypes from 'prop-types';

import {Error} from './style';

/**
 * An error message component for a contact form field.
 */
const ErrorMessage = (props) => (
  <Error css={props.css}>{props.text}</Error>
);

ErrorMessage.propTypes = {
  text: PropTypes.string,
  css: PropTypes.array
}

ErrorMessage.defaultProps = {
  text: 'Please complete the field.'
}

export default ErrorMessage;
