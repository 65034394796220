import styled from 'styled-components';

export const FlexWrapper = styled.div`
  display: block;

  @media (min-width: ${props => props.theme.breakpoints.contactContent}) {
    display: flex;
  }
`;

export const FormWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.contactContent}) {
    margin-right: 5rem;
    width: 74%;
  }
`;

export const Title = styled.h2`
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;
  margin-bottom: 1.25rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.75rem;
  }
`;

export const Paragraph = styled.p`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: ${props => props.theme.fonts.size.paragraph};
  margin-bottom: 2.5rem;
  margin-top: 1.25rem;
`;

export const GradientText = styled.span`
  color: #000;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;

  @supports (-webkit-background-clip: text) {
    -webkit-background-clip: text;
            background-clip: text;
    background-image: linear-gradient(to right, ${props => props.theme.colors.gradient});
    color: transparent;
  }
`;

export const PhoneWrapper = styled.div`
  & > ${Title} {
    margin-top: 4.375rem;

    @media (min-width: ${props => props.theme.breakpoints.contactContent}) {
      margin-top: 0;
    }
  }

  & > ${Paragraph} {
    margin-bottom: 1.25rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.contactContent}) {
    width: 26%;
  }
`;
