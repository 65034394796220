const original = {
  colors: {
    gradient: '#0086CC, #00CA80',
    heading: '#FFF',
    footer: '#000'
  },
  breakpoints: {
    medium: '50rem',
    contactContent: '61.375rem',
    capabilitiesContent: '62.5rem',
    indexProjectsContent: '53.25rem',
    footerTagline: '63.5rem',
    footerContent: '32.1875rem'
  },
  fonts: {
    family: {
      heading: 'Roboto, sans-serif',
      paragraph: 'Courier, serif'
    },
    size: {
      heading: ['1.5rem', '2.5rem'],
      paragraph: '1rem'
    },
    lineHeight: {
      heading: ['1.75rem', '2.9375rem'],
      paragraph: '1.5625rem'
    }
  },
  margins: {
    content: {
      small: '1.875rem',
      medium: '6.25rem'
    },
    runner: {
      small: '1.875rem',
      medium: '4.6875rem'
    }
  },
  pageWidth: '90rem'
};

export const theme = original;
