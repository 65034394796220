import React from 'react';
import PropTypes from 'prop-types';
import {css} from 'styled-components';

import {TabWrapper, Tab, Title, Text, Year} from './style';
import placeholder from '../../assets/images/projectPlaceholder.jpg';
import ScrollNavigate from '../ScrollNavigate';
import {routes} from '../../utils/routes';

// Scroll Nav Styling.
const scrollNavCSS = css`
  background: linear-gradient(to right, ${props => props.theme.colors.gradient});
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-out;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    &:hover {
      opacity: 0.75;
    }
  }
`;

/**
 * A project image tab component.
 */
const ProjectTab = (props) => {
  const {project} = props;
  return (
    <TabWrapper large={props.large}>
      <Tab image={project.landingImage ? project.landingImage : placeholder}>
        <ScrollNavigate
          newPageURL={`${routes.projects}/${project.slug}`}
          css={scrollNavCSS}
        />
      </Tab>
      <Title large={props.large}>
        <Text>{project.name}</Text>
        <Year>{project.date.substring(0, 4)}</Year>
      </Title>
    </TabWrapper>
  );
};

ProjectTab.propTypes = {
  project: PropTypes.object.isRequired
}

export default ProjectTab;
