import styled, {css} from 'styled-components';

const defaultCSS = css`
  background: #FAFAFA;
  border: 2px solid #000;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  font-family: sans-serif;
  font-size: 1rem;
  padding: 1rem 1.25rem;
  width: 100%;
`;

export const InputField = styled.input`
  ${props => props.css};
`;

InputField.defaultProps = {
  css: defaultCSS
}
