import styled from 'styled-components';

export const ProjectWrapper = styled.div`
  margin: 3.125rem auto;

  & > div {
    margin: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: flex;
    margin: 6.25rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ProjectInfo = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.indexProjectsContent}) {
    display: block;
    padding-right: 6.25rem;
    width: 35%;
  }
`;

export const Title = styled.h3`
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
`;

export const Year = styled.span`
  display: block;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  margin-bottom: 1.25rem;
`;
