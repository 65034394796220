import React from 'react';
import PropTypes from 'prop-types';

import {Button} from './style';

/**
 * A scroll button component.
 * Scrolls back to the top of the page when clicked.  Child image components
 * will be rendered within the button.
 * @param {object} props - The component props.
 */
 class ScrollButton extends React.Component {
   /**
    * Scrolls to the top of the page.
    */
   handleClick() {
     window.scroll({
       top: 0,
       left: 0,
       behavior: 'smooth'
     });
   }

   /**
    * Renders the component.
    */
   render() {
     return (
       <Button
         onClick={this.handleClick}
         css={this.props.css}>
         {this.props.children}
       </Button>
     );
   }
 }

ScrollButton.propTypes = {
  css: PropTypes.array
}

export default ScrollButton;
