import React from 'react';
import PropTypes from 'prop-types';

import {InputField} from './style';

/**
 * An input field component.
 * @param {object} props - The component props.
 */
const Input = (props) => (
  <InputField
    type={props.type}
    value={props.value}
    name={props.name}
    placeholder={props.placeholder}
    onChange={props.handleChange}
    css={props.css}
  />
);

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  css: PropTypes.array
}

Input.defaultProps = {
  type: 'text',
  placeholder: 'Placeholder Text'
}

export default Input;
