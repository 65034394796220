import React from 'react';
import {routes} from '../../utils/routes';
import {css} from 'styled-components';
import {theme} from '../../utils/themes';

import {PageWrapper} from '../../utils/style';
import {
  FooterWrapper,
  FooterContent,
  Logo,
  LegalWrapper,
  Tagline,
  Dot,
  ScrollArrow
} from './style';
import ScrollNavigate from '../ScrollNavigate';
import {lines} from '../../utils/helpers';
import ScrollButton from '../ScrollButton';

// Scroll Navigate Wrapper CSS.
const scrollNavCSS = css`
  flex: 1 1 auto;
  justify-content: center;
  order: 1;

  @media (min-width: ${props => props.theme.breakpoints.footerContent}) {
    flex: 0 0 auto;
  }
`;

const scrollLinkCSS = css`
  color: #fff;
  display: inline-block;
  font-family: ${theme.fonts.family.paragraph};
  font-size: 0.75rem;
  margin-bottom: 2px;
  text-decoration: underline;
  vertical-align: middle;

  @media (min-width: ${props => props.theme.breakpoints.footerContent}) {
    font-size: 0.75rem;
  }
`;

// Scroll Button CSS.
const buttonCSS = css`
  background: transparent;
  border: none;
  cursor: pointer;
  order: 4;
  padding: 0 0.5rem;

  & > svg {
    display: inline-block;
  }
`;

/**
 * The footer component.
 */
 const Footer = () => (
   <FooterWrapper>
     <PageWrapper>
       <FooterContent>
         <ScrollNavigate newPageURL={routes.profile} css={scrollNavCSS}>
           <Logo />
         </ScrollNavigate>
         <LegalWrapper>
           <Dot />
           <ScrollNavigate newPageURL={routes.terms} css={scrollLinkCSS}>
             Terms
           </ScrollNavigate>
           <Dot />
           <ScrollNavigate newPageURL={routes.privacy} css={scrollLinkCSS}>
             Privacy
           </ScrollNavigate>
         </LegalWrapper>
         <Tagline>{`${lines[0]} ${lines[1]} ${lines[2]}`}</Tagline>
         <ScrollButton css={buttonCSS}>
           <ScrollArrow />
         </ScrollButton>
       </FooterContent>
     </PageWrapper>
   </FooterWrapper>
 );

export default Footer;
