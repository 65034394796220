import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const defaultOverlayCSS = css`
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  height: 100%;
  left: 0;
  opacity: ${props => props.active ? '1' : '0'};
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-out,
              visibility 0.3s ease-out;
  visibility: ${props => props.active ? 'visible' : 'hidden'};
`;

const defaultWrapperCSS = css`
  bottom: 0;
  padding: 0 1.875rem;
  padding-bottom: 3.125rem;
  position: absolute;
`;

const defaultLinkCSS = css`
  color: #FFF;
  display: block;
  font-family: sans-serif;
  font-size: 2.5rem;
  margin: 1.875rem 0;
  text-decoration: none;
  text-transform: uppercase;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Overlay = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  ${props => props.css};
`;

Overlay.propTypes = {
  css: PropTypes.array
}

Overlay.defaultProps = {
  css: defaultOverlayCSS
}

export const NavWrapper = styled.div`
  ${props => props.css}
`;

NavWrapper.propTypes = {
  css: PropTypes.array
}

NavWrapper.defaultProps = {
  css: defaultWrapperCSS
}

export const NavLink = styled(Link)`
  ${props => props.css};
`;

NavLink.propTypes = {
  css: PropTypes.array
}

NavLink.defaultProps = {
  css: defaultLinkCSS
}
