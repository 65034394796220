import React from 'react';
import PropTypes from 'prop-types';

import {Button} from './style';

/**
 * A navigation hamburger component.
 * @param {object} props - The component props.
 */
const Hamburger = (props) => (
  <Button
    onClick={props.handleClickFunc}
    css={props.css}>
    {props.children}
  </Button>
);

Hamburger.propTypes = {
  handleClickFunc: PropTypes.func.isRequired,
  css: PropTypes.array
}

Hamburger.defaultProps = {
  handleClickFunc: () => null
}

export default Hamburger;
