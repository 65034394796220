import styled, {css} from 'styled-components';

const defaultCSS = css`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    color: ${props => props.theme.colors.heading};
    display: inline;
    font-family: ${props => props.theme.fonts.family.heading};
    font-size: 12.5vw;
    max-width: ${props => props.theme.pageWidth};
    opacity: 0.25;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-75%);
    width: 100%;
  }

  @media (min-width: ${props => props.theme.pageWidth}) {
    font-size: 11.25rem;
  }
`;

export const TypedText = styled.span`
  ${props => props.css}
`;

TypedText.defaultProps = {
  css: defaultCSS
}
