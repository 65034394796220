import styled from 'styled-components';
import {ReactComponent as LogoIcon} from '../../assets/images/logo.svg';
import {ReactComponent as DotIcon} from '../../assets/images/dot.svg';
import {
  ReactComponent as ScrollArrowIcon
} from '../../assets/images/scroll.svg';

export const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.footer};
  flex-shrink: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${props => props.theme.margins.runner.small};
  padding: 1.6875rem 0;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.footerContent}) {
    flex-wrap: nowrap;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin: 0 ${props => props.theme.margins.runner.medium};
    padding: 3.125rem 0;
  }
`;

export const Logo = styled(LogoIcon)`
  height: auto;
  width: 9.625rem;
  vertical-align: middle;
`;

export const Tagline = styled.span`
  color: ${props => props.theme.colors.heading};
  display: none;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  margin-right: 4.375rem;
  order: 3;

  @media (min-width: ${props => props.theme.breakpoints.footerTagline}) {
    display: inline-block;
  }
`;

export const LegalWrapper = styled.div`
  flex: 1 1 auto;
  margin-top: 1rem;
  order: 5;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.footerContent}) {
    margin: 0;
    order: 2
    width: auto;
  }
`;

export const Dot = styled(DotIcon)`
  height: auto;
  margin: 0 1.25rem;
  vertical-align: middle;
  width: 0.375rem;

  &:first-of-type {
    display: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.footerContent}) {
    display: inline-block;

    &:first-of-type {
      display: inline-block;
    }
  }
`;

export const ScrollArrow = styled(ScrollArrowIcon)`
  height: auto;
  width: 1.3125rem;
`;
