import React from 'react';
import PropTypes from 'prop-types';

import {Branding} from './style';

/**
 * A reCaptcha google branding component.
 * This is required when hiding google's invisible reCaptcha badge.
 */
const GoogleBranding = (props) => (
  <Branding css={props.css}>
    This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy'>Privacy Policy</a> and <a href='https://policies.google.com/terms'>Terms of Service</a> apply.
  </Branding>
);

GoogleBranding.propTypes = {
  css: PropTypes.array
}

export default GoogleBranding;
