import styled, {css} from 'styled-components';

const defaultCSS = css`
  color: red;
  font-family: sans-serif;
  font-size: 0.75rem;
  position: absolute;
  margin-top: 0.3125rem;
`;

export const Error = styled.span`
  ${props => props.css};
`;

Error.defaultProps = {
  css: defaultCSS
}
