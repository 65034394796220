import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {
  TransitionWrapper,
  PageWrapperFlex,
  ContentWrapper,
  LoadingWidgetWrapper,
  Link
} from '../../utils/style';
import {
  ProjectWrapper,
  ProjectInfo,
  Title,
  Year
} from './style';
import Header from '../Header';
import LoadingWidget from '../LoadingWidget';
import ProjectTab from '../ProjectTab';
import Footer from '../Footer';

/**
 * The projects page component.
 */
class ProjectsPage extends React.Component {
  /**
   * Creates a ProjectsPage component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0
    }
    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
  }

  /**
   * Invoked immediately after the comonent is loaded.
   */
  componentDidMount() {
    if (this.props.isPageRefreshed) {
      this.props.pageRefreshFunc(false);
    }
  }

  /**
   * Updates the state representing the height of the page's header element.
   * @param {number} height - The height of the header in pixels.
   */
  updateHeaderHeight(height) {
    this.setState({headerHeight: height});
  }

  /**
   * Renders the component.
   */
  render() {
    const {APIData} = this.props;
    // The height for the flex wrapper to enable a sticky footer.
    const height = 'calc(100vh - ' + this.state.headerHeight + 'px)';
    return (
      <React.Fragment>
        <Helmet>
          <title>Projects | Pixel Sketch</title>
          <meta
            name="description"
            content="A sample of our Bespoke Web Design and Development
                     projects."
          />
          <link rel="canonical" href="https://pixelsketch.co.uk/projects" />
        </Helmet>
        <TransitionWrapper>
          <Header
            text="Websites that enhance your business."
            maxTextWidth={['16rem', '27rem']}
            updateHeightFunc={this.updateHeaderHeight}
          />
          <PageWrapperFlex style = {{height: height}}>
            <ContentWrapper>
              {APIData.projects && Object.keys(APIData.projects).length > 0
                ? APIData.projects.map((project) =>
                    <ProjectWrapper key={project.name}>
                      <ProjectInfo>
                        <Title>{project.name}</Title>
                        <Year>{project.date.substring(0, 4)}</Year>
                        {project.url
                          ? <Link href={project.url} target='_blank'>
                              Website
                            </Link>
                          : null}
                      </ProjectInfo>
                      <ProjectTab
                        project={project}
                        large
                      />
                    </ProjectWrapper>
                  )
                : <LoadingWidgetWrapper>
                    <LoadingWidget />
                  </LoadingWidgetWrapper>}
            </ContentWrapper>
            <Footer />
          </PageWrapperFlex>
        </TransitionWrapper>
      </React.Fragment>
    );
  }
}

ProjectsPage.propTypes = {
  APIData: PropTypes.object.isRequired,
  isPageRefreshed: PropTypes.bool.isRequired,
  pageRefreshFunc: PropTypes.func.isRequired
}

export default ProjectsPage;
