import React from 'react';
import PropTypes from 'prop-types';
import {css} from 'styled-components';
import {routes} from '../../utils/routes';
import {Helmet} from 'react-helmet';

import {
  Profile,
  Capabilities,
  CapFlexWrapper,
  PlainTitle,
  Skills,
  CapTitle,
  CapText,
  CapDivider,
  Services,
  ServiceTitle,
  ServiceText,
  Projects,
  TabFlexWrapper
} from './style';
import {
  TransitionWrapper,
  LandingWrapper,
  LandingBackground,
  PageWrapper,
  ContentWrapper,
  Text,
  LoadingWidgetWrapper
} from '../../utils/style';
import Tagline from '../Tagline';
import Typewriter from '../Typewriter';
import HeaderTitle from '../HeaderTitle';
import Title from '../Title';
import LoadingWidget from '../LoadingWidget';
import ProjectTab from '../ProjectTab';
import ScrollNavigate from '../ScrollNavigate';
import LinkButton from '../LinkButton';
import Footer from '../Footer';

// Header Content.
import {lines} from '../../utils/helpers';
const title = 'Bespoke websites for small businesses.';

// Title Styling.
const wrapperCSS = css`
  margin-bottom: 3.125rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin-bottom: 4.375rem;
  }
`;

const backgroundCSS = css`
  color: #00CA80;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 3.427rem;
  opacity: 0.2;
  text-transform: uppercase;

  @supports (-webkit-background-clip: text) {
    -webkit-background-clip: text;
            background-clip: text;
    background-image: linear-gradient(
      to right, ${props => props.theme.colors.gradient}
    );
    color: transparent;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 10vw;
  }

  @media (min-width: ${props => props.theme.pageWidth}) {
    font-size: 9rem;
  }

  @supports (-moz-appearance:meterbar) {
    opacity: 0.3;
  }
`;

const foregroundCSS = css`
  color: #0086CC;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: ${props => props.theme.fonts.size.heading[0]};
  transform: translate(-50%, -50%);

  @supports (-webkit-background-clip: text) {
    -webkit-background-clip: text;
            background-clip: text;
    background-image: linear-gradient(
      to right, ${props => props.theme.colors.gradient}
    );
    color: transparent;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: ${props => props.theme.fonts.size.heading[1]};
  }
`;

const titleCSS = {
  wrapper: wrapperCSS,
  background: backgroundCSS,
  foreground: foregroundCSS
}

// Scroll Navigate Wrapper CSS.
const scrollNavCSS = css`
  margin: 0 auto;
  max-width: 15.625rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    max-width: 18.75rem;
  }
`;

// Link Button Styling.
const linkButtonCSS = css`
  background-clip: padding-box, border-box;
  background-image: linear-gradient(#FFF, #FFF),
                    linear-gradient(
                      to right, ${props => props.theme.colors.gradient}
                    );
  background-origin: border-box;
  background-size: cover;
  border: 2px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 0.875rem 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:after {
    background-image: linear-gradient(
      to right, ${props => props.theme.colors.gradient}
    ),
    linear-gradient(
      to right, ${props => props.theme.colors.gradient}
    );
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    &:after {
      transition: opacity 0.4s ease-out;
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      & > span {
        color: #FFF;
      }
    }
  }
`;

const textCSS = css`
  color: #000;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  position: relative;

  @supports (-webkit-background-clip: text) {
    -webkit-background-clip: text;
            background-clip: text;
    background-image: linear-gradient(
      to right, ${props => props.theme.colors.gradient}
    );
    color: transparent;
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    z-index: 1;
  }
`;

const linkCSS = {
  button: linkButtonCSS,
  text: textCSS
}

/**
 * The index page component.
 */
 class IndexPage extends React.Component {
   /**
    * Invoked immediately after the component is loaded.
    */
   componentDidMount() {
     if (this.props.isPageRefreshed) {
       this.props.pageRefreshFunc(false);
     }
   }

   /**
    * Renders the component.
    */
   render() {
     const {APIData, isPageRefreshed, viewportHeight} = this.props;
     return (
       <React.Fragment>
         <Helmet>
           <title>
             Web Design South East - Bespoke Websites | Pixel Sketch
           </title>
           <meta
             name="description"
             content="Web Design South East | Pixel Sketch, a full service
             Website Design and Development company specialising in Bespoke
             Websites for Small and Medium sized businesses."
           />
           <link
             rel="canonical"
             href="https://pixelsketch.co.uk"
           />
         </Helmet>
         <TransitionWrapper>
           <LandingWrapper>
             <LandingBackground height={viewportHeight} />
             <PageWrapper>
               <Typewriter textPool={lines} isPageRefreshed={isPageRefreshed} />
               <Tagline lines={lines} />
               <HeaderTitle text={title} />
             </PageWrapper>
           </LandingWrapper>

           <Profile>
             <PageWrapper>
               <ContentWrapper>
                 <Title
                   background='Welcome'
                   foreground='Who We Are'
                   css={titleCSS}
                 />
                 <Text>
                   We’re a South East based web development company specialising
                   in creating bespoke websites for small-to-medium sized
                   businesses.
                 </Text>
                 <Text>
                   We understand that your website is central to your brand
                   identity and how your customers perceive you.  At the outset
                   we'll take the time to understand your business so that we
                   can successfully fulfil your commercial objectives.
                 </Text>
                 <Text>
                   Effective web design isn’t just about you - it’s about your
                   customers too.  We’re always putting ourselves in the shoes
                   of a customer to ensure that we deliver a stellar experience
                   for everyone.
                 </Text>
                 <Text>
                   With Pixel Sketch you’ll enjoy an affordable and flexible
                   creative process resulting in a stunning website tailored
                   around improving your online business.
                 </Text>
               </ContentWrapper>
             </PageWrapper>
           </Profile>

           <Capabilities>
             <PageWrapper>
               <ContentWrapper padding>
                 <CapFlexWrapper>
                   <PlainTitle>Capabilities</PlainTitle>
                   <Skills>
                     <CapTitle>Design</CapTitle>
                     <CapText>
                       We like to keep things simple and beautiful.  Clean
                       aesthetics that enhance functionality - keep your
                       customers engaged.
                     </CapText>
                     <CapTitle>Development</CapTitle>
                     <CapText>
                       We harness the latest technologies to ensure that your
                       business is bang up-to-date. Responsive, mobile-friendly
                       development means that your site will look stunning on
                       all devices.
                     </CapText>
                     <CapTitle>Branding</CapTitle>
                     <CapText>
                       Image is everything.  The brands people choose to
                       interact with often reflect on their own values and
                       preferences.  Let’s find what makes your brand come
                       alive.
                     </CapText>
                     <CapTitle>Training</CapTitle>
                     <CapText>
                       We’ll walk you through your new bespoke Content
                       Management System.  You’ll be in control – equipped to
                       update your content at a moment's notice.
                     </CapText>
                   </Skills>
                 </CapFlexWrapper>
                 <CapDivider />
                 <CapFlexWrapper>
                   <ServiceTitle>All Services</ServiceTitle>
                   <Services>
                     <ServiceText>
                       Web Design<br />
                       Logo Design<br />
                       Development<br />
                     </ServiceText>
                     <ServiceText>
                       Branding<br />
                       Bespoke CMS<br />
                       Web Hosting<br />
                     </ServiceText>
                     <ServiceText>
                       eCommerce<br />
                       UX Design<br />
                       Maintenance<br />
                     </ServiceText>
                   </Services>
                 </CapFlexWrapper>
               </ContentWrapper>
             </PageWrapper>
           </Capabilities>

           <Projects>
             <PageWrapper>
               <ContentWrapper>
                 <Title
                   background='Projects'
                   foreground='Latest Work'
                   css={titleCSS}
                 />
                 {Object.keys(APIData).length > 0
                   ? <TabFlexWrapper>
                       {APIData.projects.slice(0, 4).map((project) =>
                         <ProjectTab
                           key={project.name}
                           project={project}
                         />
                       )}
                     </TabFlexWrapper>
                   : <LoadingWidgetWrapper style={{marginBottom: '6.5rem'}}>
                       <LoadingWidget />
                     </LoadingWidgetWrapper>}
                 <ScrollNavigate newPageURL={routes.projects} css={scrollNavCSS}>
                   <LinkButton
                     text='View More Work'
                     route={routes.projects}
                     css={linkCSS}
                   />
                 </ScrollNavigate>
               </ContentWrapper>
             </PageWrapper>
           </Projects>
           <Footer />
         </TransitionWrapper>
       </React.Fragment>
     );
   }
 }

IndexPage.propTypes = {
  APIData: PropTypes.object.isRequired,
  isPageRefreshed: PropTypes.bool.isRequired,
  pageRefreshFunc: PropTypes.func.isRequired,
  viewportHeight: PropTypes.number.isRequired
}

export default IndexPage;
