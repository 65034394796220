import React from 'react';
import PropTypes from 'prop-types';

import {TitleWrapper, Background, Foreground} from './style';

/**
 * A title component.
 * Renders foreground text on background text.
 * @param {object} props - The component props.
 */
const Title = (props) => {
  const {background, foreground, css} = props;
  return (
    <TitleWrapper css={css && css.wrapper ? css.wrapper : undefined}>
      <Background css={css && css.background ? css.background : undefined}>
        {background}
      </Background>
      <Foreground css={css && css.foreground ? css.foreground : undefined}>
        {foreground}
      </Foreground>
    </TitleWrapper>
  );
};

Title.propTypes = {
  background: PropTypes.string,
  foreground: PropTypes.string,
  css: PropTypes.object
}

Title.defaultProps = {
  background: 'Background',
  foreground: 'Foreground'
}

export default Title;
