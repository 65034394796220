import styled from 'styled-components';

export const TaglineWrapper = styled.div`
  margin: 0 ${props => props.theme.margins.content.small};
  left: 0;
  position: absolute;
  right: 0;
  top: 30%;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: none;
  }
`;

export const Line = styled.div`
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 2.5rem;
  margin: 0.5rem 0;
  opacity: 0.25;
  text-align: center;
  text-transform: uppercase;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;
