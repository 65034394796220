import React from 'react';
import PropTypes from 'prop-types';

import {TaglineWrapper, Line} from './style';

const defaultLines = ['Line One', 'Line Two', 'Line Three'];

/**
 * A tagline component.
 * Consists of three lines of text on top of one another.
 * @param {object} props - The component props. 
 */
const Tagline = (props) => (
  <TaglineWrapper>
    <Line>{props.lines[0]}</Line>
    <Line>{props.lines[1]}</Line>
    <Line>{props.lines[2]}</Line>
  </TaglineWrapper>
);

Tagline.propTypes = {
  lines: PropTypes.array
}

Tagline.defaultProps = {
  lines: defaultLines
}

export default Tagline;
