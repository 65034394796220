import React from 'react';
import PropTypes from 'prop-types';

import {Button, Text} from './style';

/**
 * A link button component.
 * Scrolls to the top of the page before redirecting to the given route.
 */
const LinkButton = (props) => {
  const {text, css} = props;
  return (
    <Button
      css={css && css.button ? css.button : undefined}>
      <Text css={css && css.text ? css.text : undefined}>
        {text}
      </Text>
    </Button>
  );
};

LinkButton.propTypes = {
  text: PropTypes.string,
  css: PropTypes.object
}

LinkButton.defaultProps = {
  text: 'Link Button'
}

export default LinkButton;
