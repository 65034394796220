import React from 'react';

import {Widget, Square} from './style';

/**
 * An animated loading widget component.
 */
const LoadingWidget = () => (
  <Widget>
    <Square />
    <Square />
    <Square />
    <Square />
    <Square />
    <Square />
    <Square />
    <Square />
    <Square />
  </Widget>
);

export default LoadingWidget;
