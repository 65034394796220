import styled, {css} from 'styled-components';
import {theme} from './themes';
import {
  timings,
  titleDelay,
  contentDelay,
  slideUp,
  slideDown,
  fadeIn,
  fadeOut,
  fadeOutTypedText,
  translateUp,
  translateDown
} from './animations';

import {Capabilities} from '../components/IndexPage/style';
import {HeaderBackground} from '../components/Header/style';
import {Title} from '../components/HeaderTitle/style';
import {FooterWrapper} from '../components/Footer/style';
import {TypedText} from '../components/Typewriter/style';

export const PageWrapper = styled.div`
  max-width: ${theme.pageWidth};
  margin: 0 auto;
`;

// For flex based sticky footer.
export const PageWrapperFlex = styled(PageWrapper)`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ContentWrapper = styled.div`
  backface-visibility: hidden;
  flex: 1 0 auto;
  margin:  4.375rem ${props => props.theme.margins.content.small};

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin: 6.25rem ${props => props.theme.margins.content.medium};
  }

  ${props => props.padding && css`
    margin-bottom: 0;
    margin-top: 0;
    padding: 4.375rem 0;

    @media (min-width: ${props => props.theme.breakpoints.medium}) {
      ${'' /* Margin repeated due to media queries overwriting. */}
      margin-bottom: 0;
      margin-top: 0;
      padding: 6.25rem 0;
    }
  `};
`;

export const LandingWrapper = styled.section`
  position: relative;
`;

export const LandingBackground = styled.div`
  background: linear-gradient(to right, ${props => props.theme.colors.gradient});
  height: 100vh;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  width: 100vw;

  ${'' /* Override default height to account for mobile url bar. */}
  ${props => props.height && css`
    height: ${props => props.height / 16}rem;
  `};

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    height: 100vh;
  }
`;

export const TransitionWrapper = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  &.page-enter {
    ${HeaderBackground},
    ${LandingBackground} {
      animation: ${fadeIn} 0s ${timings.slideUp.duration}s forwards,
                 ${slideDown} ${timings.slideDown.duration}s ${timings.slideUp.duration}s;
      opacity: 0;
    }

    ${Title} {
      animation: ${fadeIn} ${timings.fadeInTitle.duration}s ease-out ${titleDelay}s forwards,
                 ${translateDown} ${timings.translateDown.duration}s ease-out ${titleDelay}s forwards;
      opacity: 0;
      transform: translateY(-20%);
    }

    ${'' /* Override for Landing Header slide down speed. */}
    ${LandingBackground} {
      animation: ${fadeIn} 0s ${timings.slideUp.duration}s forwards,
                 ${slideDown} ${timings.slideDown.duration}s ${timings.slideUp.duration}s;
    }

    ${ContentWrapper} {
      animation: ${fadeIn} ${timings.fadeInTitle.duration}s ease-out ${contentDelay}s forwards,
                 ${translateUp} ${timings.translateUp.duration}s ease-out ${contentDelay}s;
      opacity: 0;
      transform: translateY(20%);
    }

    ${Capabilities},
    ${FooterWrapper} {
      animation: ${fadeIn} ${timings.fadeInTitle.duration}s ease-out ${contentDelay}s forwards;
      opacity: 0;
    }
  }

  &.page-exit {
    ${HeaderBackground},
    ${LandingBackground} {
      animation: ${slideUp} ${timings.slideUp.duration}s forwards;
    }

    ${PageWrapper},
    ${Title},
    ${ContentWrapper},
    ${Capabilities},
    ${FooterWrapper} {
      animation: ${fadeOut} ${timings.fadeOut.duration}s forwards;
    }

    /* IE11 typewriter fade hack. */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      ${TypedText} {
        animation: ${fadeOutTypedText} ${timings.fadeOut.duration}s forwards;
      }
    }
  }
`;

export const Text = styled.p`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  margin: 2.25rem auto;
  max-width: 26.625rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    max-width: 31.25rem;
  }
`;

export const LoadingWidgetWrapper = styled.div`
  margin-bottom: 1rem;
  min-height: 12.5rem;
  position: relative;
`;

export const Link = styled.a`
  color: #000;
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  text-decoration: underline;

  ${props => props.hiddenSmall && css`
    display: none;

    @media(min-width: ${props => props.theme.breakpoints.medium}) {
      display: block;
    }
  `};

  ${props => props.hiddenLarge && css`
    display: block;
    margin-top: 1.875rem;

    @media(min-width: ${props => props.theme.breakpoints.medium}) {
      display: none;
    }
  `};
`;
