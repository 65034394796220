import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

// Default Styling.
const defaultWrapperCSS = css`
  margin-bottom: 1.875rem;
  margin-top: 2.0625rem;
  position: relative;

  & input:checked + label {
    color: #000;

    & a {
      color: #000;
    }
  }

  & input:checked + label:before {
    background: #000;
  }

  & input:checked + label:after {
    opacity: 1;
  }
`;

const defaultLabelCSS = css`
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  padding-left: 2.5rem;
  position: relative;
  vertical-align: top;

  & a {
    color: #000;
  }

  ${props => props.error && css`
    color: red;

    & a {
      color: red;
    }
  `};

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    border: 2px solid #000;
    border-radius: 4px;
    display: inline-block;
    height: 16px;
    left: 0;
    transition: background 0.2s ease-out;
    top: -3px;
    width: 16px;
  }

  &:after {
    border-bottom: 2px solid #FFF;
    border-left: 2px solid #FFF;
    content: '';
    display: inline-block;
    height: 4px;
    left: 5px;
    opacity: 0;
    top: 3px;
    transform: rotate(-55deg);
    transition: opacity 0.2s ease-out;
    width: 8px;
  }
`;

export const CheckboxWrapper = styled.div`
  ${props => props.css};
`;

CheckboxWrapper.propTypes = {
  css: PropTypes.array
}

CheckboxWrapper.defaultProps = {
  css: defaultWrapperCSS
}

export const Input = styled.input`
  height: 16px;
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 16px;
  ${props => props.css};
`;

Input.propTypes = {
  css: PropTypes.array
}

export const Label = styled.label`
  ${props => props.css};
`;

Label.propTypes = {
  error: PropTypes.bool,
  css: PropTypes.array
}

Label.defaultProps = {
  css: defaultLabelCSS
}
