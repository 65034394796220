import styled from 'styled-components';

export const TextWrapper = styled.div`
  margin-bottom: 4.375rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: flex;
    margin-bottom: 9.375rem;
  }
`;

export const EnterpriseWrapper = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: block;
    margin-right: 6.25rem;
    width: 35%;
  }
`;

export const Enterprise = styled.h2`
  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-family: ${props => props.theme.fonts.family.heading};
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
  }
`;

export const Description = styled.p`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    width: 65%;
  }
`;

export const DisplayImageHeader = styled.div`
  background: #000;
  padding-top: 40%;
  position: relative;
  width: 100%;
`;

export const ImageHeaderTitle = styled.h2`
  color: #FFF;
  display: inline-block;
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 4vw;
  font-weight: bold;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);

  @media (min-width: ${props => props.theme.pageWidth}) {
    font-size: 3.6rem;
  }
`;

export const DisplayImage = styled.img`
  width: 100%;
`;
