import styled, {css} from 'styled-components';

const defaultCSS = css`
  background: palevioletred;
  border: none;
  height: 2rem;
  width: 3rem;

  &:hover {
    background: pink;
  }

  &:active {
    background: purple;
  }
`;

export const Button = styled.button`
  ${props => props.css};
`;

Button.defaultProps = {
  css: defaultCSS
}
