import styled, {css} from 'styled-components';

export const Title = styled.div`
  display: block;
  margin-top: 1.5rem;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.indexProjectsContent}) {
    ${props => props.large && css`
      display: none;
    `};

    color: ${props => props.theme.colors.heading};
    margin: 2.5rem;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translateY(20px);
    transition: opacity 0.2s ease-out,
                transform 0.2s ease-out;
  }
`;

export const TabWrapper = styled.div`
  margin: 3.125rem auto;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    margin: 0;
    max-width: 100%;
    width: calc(50% - 0.9375rem);

    ${props => props.large &&  css`
      width: 100%;
    `};

    &:hover {
      & > ${Title} {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.indexProjectsContent}) {
    ${props => props.large &&  css`
      width: 65%;
    `};
  }
`;

export const Tab = styled.div`
  background: url(${props => props.image});
  background-color: #D8D8D8;
  background-size: cover;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  height: 0;
  padding-top: 62.5%;
  position: relative;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    height: 0;
    padding-top: 62.5%;
    width: 100%;
  }
`;

export const Text = styled.h3`
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: 1.25rem;
  margin-bottom: 0.4375rem;

  @media (min-width: ${props => props.theme.breakpoints.indexProjectsContent}) {
    font-size: 1.5rem;
  }
`;

export const Year = styled.span`
  font-family: ${props => props.theme.fonts.family.paragraph};
  font-size: 1rem;
`;
