import styled, {css} from 'styled-components';

const defaultCSS = css`
  color: #C5C5C5;
  display: inline-block;
  font-family: sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem;
  max-width: 19.6875rem;

  & a {
    color: #C5C5C5;
  }
`;

export const Branding = styled.span`
  ${props => props.css};
`;

Branding.defaultProps = {
  css: defaultCSS
}
