import React from 'react';
import ReactGA from 'react-ga'
import PropTypes from 'prop-types';
import {serverIpAddress} from '../../utils/settings';
import EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';

import {Form, FieldWrapper, SentWrapper} from './style';
import Input from './Input';
import TextArea from './TextArea';
import Checkbox from './Checkbox';
import Button from './Button';
import GoogleBranding from './GoogleBranding';
import ErrorMessage from './ErrorMessage';

/**
 * A contact form component.
 */
class ContactForm extends React.Component {
  /**
   * Create a ConctactForm component.
   * @constructor
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      formDetails: {
        name: '',
        email: '',
        message: '',
        checkbox: false
      },
      formErrors: {
        name: false,
        email: false,
        message: false,
        checkbox: false
      },
      formValid: false,
      formHeight: 0,
      sending: false,
      result: ''
    }
    this.recaptchaRef = React.createRef();
    this.formRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.handleCaptcha = this.handleCaptcha.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.submitData = this.submitData.bind(this);
  }

  /**
   * Invoked immediately after the component is loaded.
   */
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  /**
   * Invoked immediately before the component is unmounted;
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Updates the form height state when the browser is resized.
   * Avoids jerky div height 'jumps' between form, sending, and result.
   */
  handleResize() {
    const form = this.formRef.current;
    if (form) {
      this.setState({formHeight: this.formRef.current.clientHeight});
    }
  }

  /**
   * Updates the value of the relevant form field.
   * @param {object} e - The synthetic event object from the form field.
   */
  handleInput(e) {
    let value = '';
    e.target.type !== 'checkbox'
      ? value = e.target.value
      : value = e.target.checked
    let name = e.target.name;
    this.setState((prevState) => {
      return {
        formDetails: {...prevState.formDetails, [name]: value}
      }
    });
  }

  /**
   * Validates the given field's value against the required criteria.
   * @param {string} fieldName - The name of the field being validated.
   * @param {string} value - The value being validated.
   */
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let error = '';

    switch (fieldName) {
      case 'name':
        error = value !== '' ? false : true;
        fieldValidationErrors.name = error;
        break;
      case 'email':
        error = !EmailValidator.validate(value);
        fieldValidationErrors.email = error;
        break;
      case 'message':
        error = value !== '' ? false : true;
        fieldValidationErrors.message = error;
        break;
      case 'checkbox':
        error = value === true ? false : true;
        fieldValidationErrors.checkbox = error;
        break;
      default:
        break;
    }

    this.setState({formErrors: fieldValidationErrors});
  }

  /**
   * Validates that all fields in the form are filled in as required.
   */
  validateForm() {
    const {formDetails, formErrors} = this.state;
    let valid = true;

    // Validate each field.
    for (let field in formDetails) {
      this.validateField(field, formDetails[field]);
    }

    // Check no errors we produced.
    for (let field in formErrors) {
      if (formErrors[field] === true) {
        valid = false;
        break;
      }
    }

    // Fire the reCAPTCHA if the form is deemed as valid.
    this.setState({formValid: valid}, () => {
      if (this.state.formValid) {
        this.recaptchaRef.current.execute();
      }
    });
  }

  /**
   *
   */
  handleFormSubmit(e) {
    e.preventDefault();
    this.validateForm();
  }

  /**
   * Sets the state indicating the recaptcha has changed.
   */
  handleCaptcha(key) {
    this.setState({'g-recaptcha-response': key}, this.submitData);
  }

  async submitData() {
    this.setState({sending: true});

    ReactGA.event({
      category: 'Inquiry',
      action: 'Inquiry Message Sent'
    });

    if (this.props.hideTextFunc) {
      this.props.hideTextFunc(true);
    }

    let data = {
      formDetails: this.state.formDetails,
      'g-recaptcha-response': this.state["g-recaptcha-response"]
    }

    const response = await fetch(`https://${serverIpAddress}/api/contact`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    const result = await response.json();
    this.setState({
      result: result,
      sending: false
    });

    // Reset the ReCAPTCHA if it still exists.
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const {formErrors, formHeight, sending, result} = this.state;
    const {siteKey, checkboxInfo, css} = this.props;
    return (
      result !== ''
        ? <SentWrapper
            height={formHeight}
            css={css && css.sent ? css.sent : undefined}>
            {'error' in result
              ? `There was a problem sending your email.
                 Please try again later.`
              : 'Your email has been sent.'}
          </SentWrapper>
        : !sending
            ? <Form ref={this.formRef} onSubmit={this.handleFormSubmit}>
                <FieldWrapper css={css && css.field ? css.field : undefined}>
                  <Input
                    type='text'
                    value={this.state.formDetails.name}
                    name='name'
                    placeholder='Name'
                    handleChange={this.handleInput}
                    css={css && css.input ? css.input : undefined}
                  />
                  {formErrors.name
                    ? <ErrorMessage
                        text='Please enter your name'
                        css={css && css.error ? css.error : undefined}
                      />
                    : null}
                </FieldWrapper>
                <FieldWrapper css={css && css.field ? css.field : undefined}>
                  <Input
                    type='text'
                    value={this.state.formDetails.email}
                    name='email'
                    placeholder='Your email address'
                    handleChange={this.handleInput}
                    css={css && css.input ? css.input : undefined}
                  />
                  {formErrors.email
                    ? <ErrorMessage
                        text='Please enter a valid email address'
                        css={css && css.error ? css.error : undefined}
                      />
                    : null}
                </FieldWrapper>
                <FieldWrapper css={css && css.field ? css.field : undefined}>
                  <TextArea
                    value={this.state.formDetails.message}
                    name='message'
                    placeholder={'Tell us about your project'}
                    handleChange={this.handleInput}
                    css={css && css.textArea ? css.textArea : undefined}
                  />
                  {formErrors.message
                    ? <ErrorMessage
                        text='Please enter your message'
                        css={css && css.error ? css.error : undefined}
                      />
                    : null}
                </FieldWrapper>
                <Checkbox
                  value={this.state.formDetails.checkbox}
                  name='checkbox'
                  company={checkboxInfo && checkboxInfo.company
                            ? checkboxInfo.company
                            : undefined}
                  terms={checkboxInfo && checkboxInfo.terms
                            ? checkboxInfo.terms
                            : undefined}
                  privacy={checkboxInfo && checkboxInfo.privacy
                            ? checkboxInfo.privacy
                            : undefined}
                  error={formErrors.checkbox}
                  handleChange={this.handleInput}
                  css={css && css.checkbox ? css.checkbox : undefined}
                />
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  size='invisible'
                  sitekey={siteKey}
                  onChange={this.handleCaptcha}
                />
                <Button css={css && css.button ? css.button : undefined} />
                <GoogleBranding css={css && css.google ? css.google : undefined} />
              </Form>
            : <SentWrapper
                height={formHeight}
                css={css && css.sent ? css.sent : null}>
                {this.props.children}
              </SentWrapper>
    );
  }
}

ContactForm.propTypes = {
  siteKey: PropTypes.string.isRequired,
  checkboxInfo: PropTypes.object,
  css: PropTypes.object
}

export default ContactForm;
