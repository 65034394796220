import React from 'react';
import PropTypes from 'prop-types';

// Remove for generic checkbox.
import ScrollNavigate from '../../ScrollNavigate';

import {CheckboxWrapper, Input, Label} from './style';

/**
 * A checkbox component.
 */
const Checkbox = (props) => {
  const {
    name,
    value,
    error,
    company,
    terms,
    privacy,
    handleChange,
    css
  } = props;
  return (
    <CheckboxWrapper css={css && css.wrapper ? css.wrapper : undefined}>
      <Input
        type='checkbox'
        id='input'
        name={name}
        value={value}
        onChange={handleChange}
        css={css && css.input ? css.input : undefined}
      />
      <Label
        htmlFor='input'
        error={error}
        css={css && css.label ? css.label : undefined}>
        I confirm that I have read and agree to {company} {terms ? <ScrollNavigate newPageURL={terms}>Terms</ScrollNavigate> : <span>Terms</span>} and {privacy ? <ScrollNavigate newPageURL={privacy}>Privacy Policy</ScrollNavigate> : <span>Privacy Policy</span>}.
        {/* I confirm that I have read and agree to {company} <a href={terms}>Terms</a> and <a href={privacy}>Privacy Policy</a>. */}
      </Label>
    </CheckboxWrapper>
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string,
  terms: PropTypes.string,
  privacy: PropTypes.string,
  error: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  css: PropTypes.object
}

Checkbox.defaultProps = {
  company: 'the',
  // terms: '#',
  // privacy: '#'
}

export default Checkbox;
