import styled, {css} from 'styled-components';

const defaultCSS = css`
  background: #000;
  border: none;
  color: #fff;
  display: block;
  font-family: sans-serif;
  font-size: 1rem;
  margin-top: 2.5rem;
  max-width: 9.375rem;
  padding: 1rem 2.8125rem;
  width: 100%;
`;

export const FormButton = styled.button`
  ${props => props.css};
`;

FormButton.defaultProps = {
  css: defaultCSS
}
