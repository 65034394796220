import React from 'react';
import PropTypes from 'prop-types';

import {Overlay, NavWrapper, NavLink} from './style';

const NavigationOverlay = (props) => {
  const {toggleOverlayFunc, css} = props;
  return (
    <Overlay
      active={props.active}
      css={css && css.overlay ? css.overlay : undefined}>
      <NavWrapper {...props} css={css && css.wrapper ? css.wrapper : undefined}>
        {props.links.map((link) =>
          <NavLink
            to={link.route}
            key={link.name}
            onClick={toggleOverlayFunc}
            css={css && css.link ? css.link : undefined}>
            {link.name}
          </NavLink>
        )}
      </NavWrapper>
    </Overlay>
  );
};

NavigationOverlay.propTypes = {
  links: PropTypes.array,
  active: PropTypes.bool.isRequired,
  toggleOverlayFunc: PropTypes.func.isRequired,
  css: PropTypes.object
}

NavigationOverlay.defaultProps = {
  links: [
    {name: 'First Link', route: '/'},
    {name: 'Second Link', route: '/'},
    {name: 'Third Link', route: '/'}
  ]
}

export default NavigationOverlay;
