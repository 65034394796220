import styled, {keyframes} from 'styled-components';

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
`;

export const Widget = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 80px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;

  & > :nth-child(1) {
    animation-delay: 0s;
  }

  & > :nth-child(2) {
    animation-delay: -0.4s;
  }

  & > :nth-child(3) {
    animation-delay: -0.8s;
  }

  & > :nth-child(4) {
    animation-delay: -0.4s;
  }

  & > :nth-child(5) {
    animation-delay: -0.8s;
  }

  & > :nth-child(6) {
    animation-delay: -1.2s;
  }

  & > :nth-child(7) {
    animation-delay: -0.8s;
  }

  & > :nth-child(8) {
    animation-delay: -1.2s;
  }

  & > :nth-child(9) {
    animation-delay: -1.6s;
  }

  & > :nth-child(3n - 2) {
    background: linear-gradient(to right, #0086CC, #009CB3);
  }

  & > :nth-child(3n - 1) {
    background: linear-gradient(to right, #009CB3, #00B598);
  }

  & > :nth-child(3n) {
    background: linear-gradient(to right, #00B598, #00CA80);
  }
`;

export const Square = styled.div`
  box-sizing: border-box;
  animation: ${fadeInOut} 1.2s linear infinite;
  height: calc(33% - 0.375rem);
  margin: 0.1875rem;
  width: calc(33% - 0.375rem);
`;
