import React from 'react';
import PropTypes from 'prop-types';

import {NavList, ListItem, MenuLink} from './style';

/**
 * A navigation list component.
 * @param {object} props - The component props.
 */
const Menu = (props) => {
  const {items, css} = props;
  return (
    <NavList css={css && css.list ? css.list : undefined}>
      {items.map((item) =>
        <ListItem key={item.name} css={css && css.item ? css.item : undefined}>
          {item.route === '/'
            ? <MenuLink
                exact
                to={item.route}
                css={css && css.link ? css.link : undefined}>
                {item.name}
              </MenuLink>
            : <MenuLink
                to={item.route}
                css={css && css.link ? css.link : undefined}>
                {item.name}
              </MenuLink>}
        </ListItem>
      )}
    </NavList>
  );
};

Menu.propTypes = {
  items: PropTypes.array,
  css: PropTypes.object
}

Menu.defaultProps = {
  items: [
    {name: 'Item One', route: '/one'},
    {name: 'Item Two', route: '/two'},
    {name: 'Item Three', route: '/three'}
  ]
}

export default Menu;
