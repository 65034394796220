import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

const defaultButtonCSS = css`
  background: palevioletred;
  border: none;
  padding: 1rem 2rem;
  text-decoration: none;

  &:hover {
    background: pink;
  }

  &:active {
    background: purple;
  }
`;

const defaultTextCSS = css`
  color: #FFF;
  font-family: sans-serif;
  font-size: 1rem;
`;

export const Button = styled.button`
  ${props => props.css};
`;

Button.propTypes = {
  css: PropTypes.array
}

Button.defaultProps = {
  css: defaultButtonCSS
}

export const Text = styled.span`
  ${props => props.css};
`;

Text.propTypes = {
  css: PropTypes.array
}

Text.defaultProps = {
  css: defaultTextCSS
}
