import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

import {
  TransitionWrapper,
  PageWrapperFlex,
  ContentWrapper
} from '../../utils/style';
import {
  Heading,
  Paragraph,
  List,
  ListItem,
  Table,
  HeaderCell,
  TableTitle,
  ContentCell,
  TableContent
} from './style';
import Header from '../Header';
import Footer from '../Footer';

/**
 * The privacy policy page componenet.
 */
class PrivacyPage extends React.Component {
  /**
   * Create a PrivacyPage component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0
    }
    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
  }

  /**
   * Invoked immediately after the page is loaded.
   */
  componentDidMount() {
    if (this.props.isPageRefreshed) {
      this.props.pageRefreshFunc(false);
    }
  }

  /**
   * Updates the state representing the height of the page's header element.
   * @param {number} height - The height of the header in pixels.
   */
  updateHeaderHeight(height) {
    this.setState({headerHeight: height});
  }

  /**
   * Renders the component.
   */
  render() {
    // The height for the flex wrapper to enable a sticky footer.
    const height = 'calc(100vh - ' + this.state.headerHeight + 'px)';
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Privacy Policy | Pixel Sketch
          </title>
          <meta
            name="description"
            content="Pixel Sketch is strongly commited to protecting the privacy
                     of users of this website."
          />
          <link
            rel="canonial"
            href="https://pixelsketch.co.uk/privacy"
          />
        </Helmet>
        <TransitionWrapper>
          <Header
            text="Privacy Policy"
            updateHeightFunc={this.updateHeaderHeight}
          />
          <PageWrapperFlex style={{height: height}}>
            <ContentWrapper>
              <Paragraph>
                Pixel Sketch is strongly commited to protecting the privacy of
                users of this website.  We collect data on this website via:
              </Paragraph>

              <Heading>Contact Forms</Heading>
              <Paragraph>
                Data captured through our contact forms will be used to contact
                you in order to respond to your enquiry.  Only authorised
                individuals will be able to access your data and it will not be
                disclosed to third parties.  Technical and operational processes
                have been put in place to keep your data safe and SSL encryption
                is used to transmit information from end users to their hosted
                servers.
              </Paragraph>

              <Heading>Google Analytics</Heading>
              <Paragraph middle>
                Google Analytics is a web analysis service provided by Google
                Inc. (“Google”).  We use this service on our website to collect
                standard internet log information and details of visitor
                behaviour patterns.  We do this to make sure it’s meeting its
                users’ needs and to understand how we could do it better.  Google
                Analytics stores information about what pages you visit,
                how long you are on the site, how you got here and what you
                click on.  This information is only processed in a way which
                does not identify anyone and we do not make any attempt to find
                out the identities of those visiting our website.
              </Paragraph>
              <Paragraph>
                Google utilises the Data collected to track and examine the use
                of this Application, to prepare reports on its activities and
                share them with other Google services.  Google may use the
                Data collected to contextualise and personalise the ads of its
                own advertising network.
              </Paragraph>

              <Heading>Cookies</Heading>
              <Paragraph middle>
                Cookies are small data files that are placed on your computer
                as you browse our website.  Most websites do this too.
              </Paragraph>
              <Paragraph middle>
                They improve things by:
              </Paragraph>
              <List>
                <ListItem>
                  remembering settings, so you don’t have to keep re-entering
                  them whenever you visit a new page
                </ListItem>
                <ListItem>
                  remembering information you’ve given (eg your postcode) so you
                  don’t need to keep entering it
                </ListItem>
                <ListItem>
                  measuring how you use the website so that we can make sure it
                  meets your needs
                </ListItem>
              </List>
              <Paragraph middle>
                Our cookies aren’t used to identify you personally.  They’re
                just here to make the site work better for you.  Indeed, you can
                manage and/or delete these small files as you wish.  To learn
                more about cookies and how to manage them, visit&nbsp;
                <a
                  href='https://www.aboutcookies.org/'
                  target='_blank'
                  rel="noopener noreferrer">
                  AboutCookies.org
                </a>.
              </Paragraph>
              <Paragraph middle>
                This table shows the Cookies we use on our site, and their
                purpose:
              </Paragraph>
              {/* Desktop */}
              <Table>
                <HeaderCell provider>
                  <TableTitle>Name of Provider</TableTitle>
                </HeaderCell>
                <HeaderCell cookie>
                  <TableTitle>Name of Cookie</TableTitle>
                </HeaderCell>
                <HeaderCell purpose>
                  <TableTitle>Purpose</TableTitle>
                </HeaderCell>
                <ContentCell provider>
                  <TableContent>Google</TableContent>
                </ContentCell>
                <ContentCell cookie>
                  <TableContent>_ga<br/>_gat<br/>_gid<br/></TableContent>
                </ContentCell>
                <ContentCell purpose>
                  <TableContent>
                    These cookies are associated with Google Universal
                    Analytics.  This cookie is used to distinguish unique users
                    by assigning a randomly generated number as a client
                    identifier.  It is included in each page request in a site
                    and used to calculate visitor, session and campaign data for
                    the sites analytics reports.
                  </TableContent>
                </ContentCell>
              </Table>
              {/* Mobile - Probably should use Grid for this to avoid
                  duplication */}
              <Table mobile>
                <HeaderCell provider>
                  <TableTitle>Name of Provider</TableTitle>
                </HeaderCell>
                <ContentCell provider>
                  <TableContent>Google</TableContent>
                </ContentCell>
                <HeaderCell cookie>
                  <TableTitle>Name of Cookie</TableTitle>
                </HeaderCell>
                <ContentCell cookie>
                  <TableContent>_ga<br/>_gat<br/>_gid<br/></TableContent>
                </ContentCell>
                <HeaderCell purpose>
                  <TableTitle>Purpose</TableTitle>
                </HeaderCell>
                <ContentCell purpose>
                  <TableContent>
                    These cookies are associated with Google Universal
                    Analytics.  This cookie is used to distinguish unique users
                    by assigning a randomly generated number as a client
                    identifier.  It is included in each page request in a site
                    and used to calculate visitor, session and campaign data for
                    the sites analytics reports.
                  </TableContent>
                </ContentCell>
              </Table>

              <Paragraph style={{marginBottom: '0', marginTop: '3.125rem'}}>
                If you have any questions or concerns about this, please email
                us at privacy@pixelsketch.co.uk
              </Paragraph>
            </ContentWrapper>
            <Footer />
          </PageWrapperFlex>
        </TransitionWrapper>
      </React.Fragment>
    );
  }
}

PrivacyPage.propTypes = {
  isPageRefreshed: PropTypes.bool.isRequired,
  pageRefreshFunc: PropTypes.func.isRequired
}

export default PrivacyPage;
