import React from 'react';
import PropTypes from 'prop-types';

import {HeaderSection, HeaderBackground} from './style';
import {PageWrapper} from '../../utils/style';
import HeaderTitle from '../HeaderTitle';

/**
 * The header component.
 */
class Header extends React.Component {
  /**
   * Creates a Header component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);
  }

  /**
   * Invoked immediately after the component is mounted.
   */
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  }

  /**
   * Invoked immdeiately before the component is unmounted.
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Updates the header height state in the parent component when the browser
   * is resized.
   */
  handleResize() {
    const {updateHeightFunc} = this.props;
    if (updateHeightFunc) {
      updateHeightFunc(this.headerRef.current.clientHeight);
    }
  }

  /**
   * Renders the component.
   */
  render() {
    const {text, maxTextWidth} = this.props;
    return (
      <HeaderSection>
        <HeaderBackground ref={this.headerRef} />
        <PageWrapper>
          <HeaderTitle text={text} maxWidth={maxTextWidth} />
        </PageWrapper>
      </HeaderSection>
    );
  }
}

Header.propTypes = {
  text: PropTypes.string,
  maxTextWidth: PropTypes.array,
  updateHeightFunc : PropTypes.func
}

export default Header;
