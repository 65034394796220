import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

const fieldWrapperCSS = css`
    margin: 1.875rem 0;
    position: relative;
`;

const defaultSentCSS = css`
  font-family: sans-serif;
  font-size: 1rem;
  position: relative;
  white-space: pre-line;

  & > div {
    
  }
`;

export const Form = styled.form`
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const FieldWrapper = styled.div`
  ${props => props.css};
`;

FieldWrapper.propTypes = {
  css: PropTypes.array
}

FieldWrapper.defaultProps = {
  css: fieldWrapperCSS
}

export const SentWrapper = styled.div`
  height: ${props => props.height}px;
  ${props => props.css};
`;

SentWrapper.propTypes = {
  height: PropTypes.number.isRequired
}

SentWrapper.defaultProps = {
  css: defaultSentCSS
}
