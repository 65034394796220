import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

// Default CSS.
const defaultListCSS = css`
  display: none;
  font-family: ${props => props.theme.fonts.family.paragraph};
  position: absolute;
  right: 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    display: inline-block;
  }
`;

const defaultItemCSS = css`
  display: inline-block;
  margin-right: 2.5rem;

  &:last-of-type {
    margin-right: 0;
  }

  a {
    color: ${props => props.theme.colors.heading};
    text-decoration: none;
  }

  a:hover {
    border-bottom: 2px solid white;
  }
`;

const defaultLinkCSS = css`
  border-bottom: 2px solid white;
`;

// Styled Components
export const NavList = styled.ul`
  ${props => props.css};
`;

NavList.propTypes = {
  css: PropTypes.array
}

NavList.defaultProps = {
  css: defaultListCSS
}

export const ListItem = styled.li`
  ${props => props.css}
`;

ListItem.propTypes = {
  css: PropTypes.array
}

ListItem.defaultProps = {
  css: defaultItemCSS
}

export const MenuLink = styled(NavLink)`
  &.${(props) => props.activeClassName} {
    ${props => props.css}
  }
`;

MenuLink.propTypes = {
  activeClassName: PropTypes.string,
  css: PropTypes.array
}

MenuLink.defaultProps = {
  activeClassName: 'active',
  css: defaultLinkCSS
}
