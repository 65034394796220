import styled from 'styled-components';

export const Title = styled.h1`
  bottom: 0;
  color: ${props => props.theme.colors.heading};
  font-family: ${props => props.theme.fonts.family.heading};
  font-size: ${props => props.theme.fonts.size.heading[0]};
  line-height: ${props => props.theme.fonts.lineHeight.heading[0]};
  margin: 0 ${props => props.theme.margins.content.small};
  margin-bottom: 3.125rem;
  max-width: ${props => props.maxWidth[0]};
  position: absolute;

  @media (min-width: ${props => props.theme.breakpoints.medium}) {
    font-size: ${props => props.theme.fonts.size.heading[1]};
    line-height: ${props => props.theme.fonts.lineHeight.heading[1]};
    margin: 0 ${props => props.theme.margins.content.medium};
    margin-bottom: ${props => props.theme.margins.content.medium};
    max-width: ${props => props.maxWidth[1]};
  }
`;
