import {serverIpAddress, protocol} from './settings';

const projectsAPIURL = `${protocol}://${serverIpAddress}/api/projects`;

async function getEndpointData(endpointURL) {
  const response = await fetch(endpointURL);
  return await response.json();
}

export async function getAPIData() {
  const projects = await getEndpointData(projectsAPIURL);

  const results = {
    projects: projects
  }
  return results;
}
