import {keyframes} from 'styled-components';

export const timings = {
  slideUp: {
    duration: 0.75
  },
  slideDown: {
    duration: 0.75
  },
  fadeInTitle: {
    duration: 0.7,
    delay: -0.4
  },
  fadeInContent: {
    duration: 0.5
  },
  fadeOut: {
    duration: 0.2
  },
  translateUp: {
    duration: 0.5
  },
  translateDown: {
    duration: 0.5
  },
  hamburgerTransition: {
    duration: 0.4,
  },
  hamburgerFade: {
    duration: 0.3,
    delay: 0.2
  },
  overlayFade: {
    duration: 0.3,
    delay: 0.2
  }
};

const titleDuration = (
  timings.translateDown.duration >= timings.fadeInTitle.duration
    ? timings.translateDown.duration
    : timings.fadeInTitle.duration
);

export const transitionLength = timings.slideUp.duration +
                                timings.slideDown.duration +
                                (titleDuration +
                                 timings.fadeInTitle.delay) +
                                 timings.translateUp.duration;

export const titleDelay = transitionLength +
                          timings.fadeInTitle.delay -
                          timings.translateUp.duration;

export const contentDelay = transitionLength +
                            timings.fadeInTitle.delay;

const fadeDuration = timings.hamburgerFade.duration
                     + timings.hamburgerFade.delay;
export const hamburgerDuration = (
  timings.hamburgerTransition.duration >= fadeDuration
    ? timings.hamburgerTransition.duration
    : fadeDuration
);

export const slideUp = keyframes`
  from { transform: translateY(0%); }
  to { transform: translateY(-100%); }
`;

export const slideDown = keyframes`
  from { transform: translateY(-100%); }
  to { transform: translateY(0%); }
`;

export const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

export const fadeOutTypedText = keyframes`
  from { opacity: 0.25; }
  to { opacity: 0; }
`;

export const translateUp = keyframes`
  from { transform: translateY(20px); }
  to { transform: translateY(0%); }
`;

export const translateDown = keyframes`
  from { transform: translateY(-20px); }
  to { transform: translateY(0%); }
`;

export const hamburgerTopCollapse = keyframes`
  from { transform: translate3d(0, 0, 0) rotate(0); }
  to { transform: translate3d(0, 0.4375rem, 0) rotate(45deg); }
`;

export const hamburgerTopReform = keyframes`
  from { transform: translate3d(0, 0.4375rem, 0) rotate(45deg); }
  to { transform: translate3d(0, 0, 0) rotate(0); }
`;

export const hamburgerBottomCollapse = keyframes`
  from { transform: translate3d(0, 0, 0) rotate(0); }
  to { transform: translate3d(0, -0.875rem, 0) rotate(-90deg); }
`;

export const hamburgerBottomReform = keyframes`
  from { transform: translate3d(0, -0.875rem, 0) rotate(-90deg); }
  to { transform: translate3d(0, 0, 0) rotate(0); }
`;
