import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

// Default CSS.
const defaultBackgroundCSS = css`
  color: palevioletred;
  font-family: sans-serif;
  font-size: 9.375rem;
  opacity: 0.1;
  text-transform: uppercase;
`;

const defaultForegroundCSS = css`
  color: #AEC6CF;
  font-family: sans-serif;
  font-size: 2.5rem;
  transform: translate(-50%, -63%);
`;

export const TitleWrapper = styled.div`
  position: relative;
  text-align: center;
  ${props => props.css};
`;

TitleWrapper.propTypes = {
  css: PropTypes.array
}

export const Background = styled.span`
  ${props => props.css};
`;

Background.propTypes = {
  css: PropTypes.array
}

Background.defaultProps = {
  css: defaultBackgroundCSS
}

export const Foreground = styled.h2`
  left: 50%;
  position: absolute;
  top: 50%;
  ${props => props.css};
`;

Foreground.propTypes = {
  css: PropTypes.array
}

Foreground.defaultProps = {
  css: defaultForegroundCSS
}
